






















import {  Vue ,Component, Prop } from 'vue-property-decorator'
import Alerta from '@/entidades/Sistema/Alerta';
import {Action} from 'vuex-class';

@Component
export default class extends Vue
{
    @Prop({type:String ,required:true})
    mensaje!:string

    @Prop({type:Boolean ,required:true})
    modal!:boolean

    descripcion:string="";

    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;

    confirmar()
    {
        if(this.descripcion =="")
        {
            this.changeLoading(new Alerta("No puede confirmar con la descripcion vacia",true,"danger",3000));
            return;
        }
        this.$emit('confirmar',this.descripcion);
    }
}
